/* Breadcrumb.module.css */
.breadcrumb {
    display: flex;
    align-items: center;
  }
  
  .breadcrumb-container {
    display: flex;
    align-items: center;
  }
  
  a {
    text-decoration: none;
    color: #333;
  }
  
  .separator {
    margin: 0 8px;
  }

  .first-crumb {
    color: gray;
  }
  