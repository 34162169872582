.header {
    display: flex;
    height: fit-content;
    margin-top: 20px;
    /* background-color: rgba(91, 91, 91, 0.5); */
    /* background-color: white; */
    /* padding: 10px; */
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    width: 100%;
    justify-content: space-between;
}

.left-side {
    margin-left: 10px;
    display: flex;
}

.right-side {
    display: flex;
}

.search-box {
    display: flex;
    padding: 2px;
    background-color: white;
    border-radius: 10px;
    margin-right: 10px;
}

.search-box > img {
    margin-left: 10px;
    margin-right: 10px;
}

.search-box > input {
    background-color: rgba(0, 0, 0, 0);
}

.logo-button {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin-right: 20px;
}

.logo-button > img {
    width: 25px;
    height: 25px;
}

.cart-button {
    background-color: #33798E;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    color: white;
    height: 100%;
    margin-right: 10px;
    cursor: pointer;
}

.cart-button > label {
    margin-top: 6px;
    cursor: pointer;
}

.user-button {
    background-color: white;
    display: flex;
    border-radius: 10px;
    padding: 10px;
}

.user-logo {
    height: 100%;
}

.user-button > label {
    margin-top: 5px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 5px;
    font-size: medium;
}

.user-button > .user-info {
    margin-top: 8px;
}

