.side-bar {
    padding: 10px;
    background-color: white;
}

.link {
    display: flex;
    cursor: pointer;
    margin-top: 10px;
}

.link > img {
    margin-right: 5px;
}

.link {
    margin-left: 2px;
    margin-right: 2px;
}

.links-container {
    padding: 10px;
}

.links-container > label {
    color: gray;
}

.options {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    margin-top: 5px;
}

.options > a {
    margin-top: 4px;
}

.image-container > img{
    cursor: pointer;
}